<template>
  <moe-page title="秒杀活动列表">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div class="df fdc">
            <div class="font-20 fwb">秒杀活动</div>
          </div>
        </div>
      </template>

      <moe-inquire @search="flashSearch">
        <el-form-item label="状态">
          <moe-select type="flashStateList" v-model="flashParams.state" placeholder="选择状态"></moe-select>
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input v-model.trim="flashParams.name" placeholder="输入活动名称" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="活动编号">
          <el-input :value="flashParams.id" @input="(value) => flashParams.id = $moe_formatter.formatterId(value)" placeholder="输入活动编号" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="使用时间" prop="startTime">
          <el-date-picker
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([flashParams.startTime, flashParams.endTime] = datetime || ['', ''])" />
        </el-form-item>
      </moe-inquire>

      <moe-table ref="flashTableRef" url="/shop/flash/list" :params="flashParams" :numberShow="false">
        <el-button slot="tool" type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/activityManage/flash/add')">新建活动</el-button>

        <el-table-column label="活动编号" prop="id" min-width="80" />
        <el-table-column label="活动名称" prop="name" min-width="120" :show-overflow-tooltip="true" />
        <el-table-column label="活动时间" min-width="310">
          <div class="df fdc aic jcc" slot-scope="{ row }">
            <div>{{ $moe_time.getTimeStamp(row.startTime) }} 至 {{ $moe_time.getTimeStamp(row.endTime) }}</div>
            <div>活动持续：{{ $moe_time.countDown(row.endTime, row.startTime) }}</div>
          </div>
        </el-table-column>
        <el-table-column label="状态" min-width="150">
          <div :class="$moe_format.getFlashStateListColor(row.state)" slot-scope="{ row }">
            {{ $moe_format.getFlashStateList(row.state) }}
          </div>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" min-width="150" />
        <el-table-column label="操作" fixed="right" width="400">
          <div class="moe-table_btns" slot-scope="{ row }">
            <el-button type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/activityManage/flash/detail`, { id: row.id })">查看活动</el-button>
            <el-button v-if="!['FINISHED'].includes(row.state)" type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/activityManage/flash/add`, { id: row.id })">编辑活动</el-button>
            <el-button v-if="['PREHEATING','IN_PROGRESS'].includes(row.state)" type="danger" size="mini" icon="el-icon-circle-close" @click="handleClose(row)">关闭</el-button>
            <el-button v-if="['NOT_STARTED'].includes(row.state)" type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
          </div>
        </el-table-column>
      </moe-table>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageFlashList',
  data() {
    return {
      flashParams: {
        pageNum: 1,
        pageSize: 10,
        id: '',
        name: '',
        state: '',
        startTime: '',
        endTime: '',
      },
      datetime: [],
    }
  },
  methods: {
    flashSearch(isSearch) {
      if (!isSearch) {
        this.flashParams = {
          pageNum: 1,
          pageSize: 10,
          id: '',
          name: '',
          state: '',
          startTime: '',
          endTime: '',
        }

        this.datetime = [];
      }

      this.$refs['flashTableRef'].searchData();
    },
    async handleClose({ name, id }) {
      this.$moe_layer.confirm(`您确定要关闭当前选项"${name}"吗？`, async () => {
        const { code, message } = await this.$moe_api.FLASH_API.closeFlash({ id });
        if (code === 200) {
          this.$moe_msg.success('关闭成功');
          this.flashSearch(false);
        } else {
          this.$moe_msg.error(message);
        }
      });
    },
    async handleDelete({ name, id }) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}"吗？`, async () => {
        const { code, message } = await this.$moe_api.FLASH_API.delFlash({ id });
        if (code === 200) {
          this.$moe_msg.success('删除成功');
          this.flashSearch(false);
        } else {
          this.$moe_msg.error(message);
        }
      });
    },
  }
}
</script>